<!--
  时间：2024年05月20日 15:38:24
-->
<template>
    <div class="footer_nav" v-if="!isMobile">
        <div class="footer_link1 w1760 cu pt90">
            <!-- <img src="../assets/icon/logo.jpg" class="logo_foot" alt=""> -->
            <div class=" flex">
                <div class="w1340 pt20 flex">
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab1') }}</div>
                        <template v-for="(item, index) in productList" :key="index">
                            <router-link class="mb15 aa" :to="'/ProductView?categoryId=' + item.categoryId">
                                <div class="fs14 lh26 ">{{ language == 'zh' ?
                                    item.categoryName : item.categoryNameEn }}</div>
                            </router-link>
                        </template>

                    </div>
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab2') }}</div>
                        <template v-for="(item, index) in resultList" :key="index">
                            <router-link :to="'/ResultView?id=' + item.categoryId" class="mb15 aa" v-if="index < 8">
                                <div class="fs14 lh26 ">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}
                                </div>
                            </router-link>
                        </template>

                    </div>
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab3') }}</div>
                        <template v-for="(item, index) in successList" :key="index">
                            <router-link class="mb15 aa" :to="'/ExampleView?id=' + item.categoryId" v-if="index < 8">
                                <div class="fs14 lh26 ">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}
                                </div>
                            </router-link>
                        </template>
                    </div>
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab4') }}</div>
                        <router-link to="/DownloadView" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('product.title5') }}</div>
                        </router-link>
                        <router-link to="/SelfService" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('support.subtitle4') }}</div>
                        </router-link>
                        <router-link to="/SingleView" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('support.subtitle5') }}</div>
                        </router-link>
                        <router-link to="/" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('support.subtitle6') }}</div>
                        </router-link>
                        <router-link to="Feedback" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('support.subtitle7') }}</div>
                        </router-link>
                        <router-link :to="'/ProductView?all=' + true" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('support.subtitle8') }}</div>
                        </router-link>
                    </div>
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab6') }}</div>
                        <router-link to="/AboutUsView?nindex=1" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('about.title13') }}</div>
                        </router-link>
                        <router-link to="/AboutUsView?nindex=0&pid=1" class="mb15 aa">
                            <div class="fs14 lh26  ">{{ $t('about.title2') }}</div>
                        </router-link>
                        <router-link to="/NewsView" class="mb15 aa">
                            <div class="fs14 lh26  ">{{ $t('about.title3') }}</div>
                        </router-link>
                        <a href="https://social.chint.com/social-recruitment/chint/40744/#/jobs?page=1&anchorName=jobsList&department%5B0%5D=733294"
                            class="mb15 aa">
                            <div class="fs14 lh26  ">{{ $t('about.title4') }}</div>
                        </a>
                        <router-link to="/ContactUsView" class="mb15 aa">
                            <div class="fs14 lh26  ">{{ $t('about.title7') }}</div>
                        </router-link>
                        <router-link to="/AboutUsView?nindex=4" class="mb15 aa">
                            <div class="fs14 lh26  ">{{ $t('about.title6') }}</div>
                        </router-link>
                        <router-link to="/NewsView" class="mb15 aa">
                        <div class="fs14 lh26  "> {{ $t('newsList.title') }}</div>
                    </router-link>
                        <!-- <a href="https://www.chint.com/foreign_subsidiary.html" target="_blank" class="mb15 aa">
                            <div class="fs16 lh26  ">{{ $t('about.title6') }}</div>
                        </a> -->
                    </div>
                    <div class="w126">
                        <div class="fs16 lh20 col23 mb25 fwb cu">{{ $t('headerTabs.tab5') }}</div>
                        <router-link to="/StudyView" class="mb15 aa cp">
                            <div class="fs14 lh26 ">{{ $t('search.title1') }}</div>
                        </router-link>
                        <router-link to="/StudyView" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('search.title2') }}</div>
                        </router-link>
                        <router-link to="/StudyView" class="mb15 aa">
                            <div class="fs14 lh26 ">{{ $t('headerTabs.tab5') }}</div>
                        </router-link>
                    </div>
                </div>
                <div class="right flex-grow-1">
                    <div class="flex-box flex-between">
                        <img src="../assets/icon/mini_logo.png" class="mini_logo" alt="">
                        <div class="tc" @click="goTop">
                            <img src="../assets/icon/footer_up.png" class="arrow" alt="">
                            <div class="fs16 lh25 mt5 col5a">{{ $t('footer.top') }}</div>
                        </div>
                    </div>
                    <div class="mt60">
                        <div class=" flex-box">
                            <div class="flex-grow-1">
                                <div class="fs16 lh25 col5a">{{ $t('footer.phone') }}</div>
                                <div class="mt10 fs14 lh25 cold2">{{ phone }}</div>
                            </div>
                            <div class="flex-grow-1">
                                <div class="fs16 lh25 col5a">{{ $t('footer.address') }}</div>
                                <div class="mt10 fs14 lh25 cold2">{{ address }}</div>
                            </div>
                        </div>
                        <div class="mt30 flex-box">
                            <div class="flex-grow-1">
                                <div class="fs16 lh25 col5a">{{ $t('footer.email') }}</div>
                                <div class="mt10 fs14 lh25 cold2">{{ email }}</div>
                            </div>
                        </div>
                        <!-- <div class="flex-box mt10">
                            <div class=" flex-grow-1">
                                <div class="fs16 lh25 col5a">{{ $t('footer.focus') }}</div>
                                <div class="mt30 flex-box">
                                    <img src="../assets/icon/icon_weixin.png" class="ico40" alt="">
                                    <img src="../assets/icon/icon_weibo.png" class="ico40 ml10" alt="">
                                </div>
                            </div>
                            <div class=" flex-grow-1">
                                <div class="fs16 lh25 col5a">{{ $t('footer.servernumber') }}</div>
                                <img src="../assets/icon/icon_code.png" class="code" alt="">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_link2">
            <div class="w1760 h80 flex-box">
                <div class="fs13" v-html="copyright"></div>
            </div>
        </div>
    </div>
    <div class="footer_nav" :class="isMobile ? 'mobile' : ''" v-else>
        <div class="footer_link1 w1760 cu pt90">
            <div class="w1340 pt20">
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab1') }}</div>
                    <template v-for="(item, index) in productList" :key="index">
                        <router-link class="mb15 aa" :to="'/ProductView?categoryId=' + item.categoryId">
                            <div class="fs26 lh30 ">{{ language == 'zh' ?
                                item.categoryName : item.categoryNameEn }}</div>
                        </router-link>
                    </template>

                </div>
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab2') }}</div>
                    <template v-for="(item, index) in resultList" :key="index">
                        <router-link :to="'/ResultView?id=' + item.categoryId" class="mb15 aa" v-if="index < 8">
                            <div class="fs26 lh30 ">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}
                            </div>
                        </router-link>
                    </template>

                </div>
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab3') }}</div>
                    <template v-for="(item, index) in successList" :key="index">
                        <router-link class="mb15 aa" :to="'/ExampleView?id=' + item.categoryId" v-if="index < 8">
                            <div class="fs26 lh30 ">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}
                            </div>
                        </router-link>
                    </template>
                </div>
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab4') }}</div>
                    <router-link to="/DownloadView" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('product.title5') }}</div>
                    </router-link>
                    <router-link to="/SelfService" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('support.subtitle4') }}</div>
                    </router-link>
                    <router-link to="/SingleView" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('support.subtitle5') }}</div>
                    </router-link>
                    <router-link to="/" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('support.subtitle6') }}</div>
                    </router-link>
                    <router-link to="Feedback" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('support.subtitle7') }}</div>
                    </router-link>
                    <router-link :to="'/ProductView?all=' + true" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('support.subtitle8') }}</div>
                    </router-link>
                </div>
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab6') }}</div>
                    <router-link to="/AboutUsView?nindex=1" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('about.title13') }}</div>
                    </router-link>
                    <router-link to="/AboutUsView?nindex=0&pid=1" class="mb15 aa">
                        <div class="fs26 lh30  ">{{ $t('about.title2') }}</div>
                    </router-link>
                    <router-link to="/NewsView" class="mb15 aa">
                        <div class="fs26 lh30  ">{{ $t('about.title3') }}</div>
                    </router-link>
                    <a href="https://social.chint.com/social-recruitment/chint/40744/#/jobs?page=1&anchorName=jobsList&department%5B0%5D=733294"
                        class="mb15 aa">
                        <div class="fs26 lh30  ">{{ $t('about.title4') }}</div>
                    </a>
                    <router-link to="/ContactUsView" class="mb15 aa">
                        <div class="fs26 lh30  ">{{ $t('about.title7') }}</div>
                    </router-link>
                    <router-link to="/AboutUsView?nindex=4" class="mb15 aa">
                        <div class="fs26 lh30  ">{{ $t('about.title6') }}</div>
                    </router-link>
                    <router-link to="/NewsView" class="mb15 aa">
                        <div class="fs26 lh30  "> {{ $t('newsList.title') }}</div>
                    </router-link>
                </div>
                <div class="w126">
                    <div class="fs32 lh34 col23 mb20 fwb cu">{{ $t('headerTabs.tab5') }}</div>
                    <router-link class="mb15 aa cp" to="/StudyView">
                        <div class="fs26 lh30 ">{{ $t('search.title1') }}</div>
                    </router-link>
                    <router-link to="/StudyView" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('search.title2') }}</div>
                    </router-link>
                    <router-link to="/StudyView" class="mb15 aa">
                        <div class="fs26 lh30 ">{{ $t('headerTabs.tab5') }}</div>
                    </router-link>
                </div>
            </div>
            <div class="right">
                <div class="flex-box flex-between">
                    <img src="../assets/icon/mini_logo.png" class="mini_logo" alt="">
                    <div class="tc" @click="goTop">
                        <img src="../assets/icon/footer_up.png" class="arrow" alt="">
                        <div class="fs30 lh34 mt5 col5a">{{ $t('footer.top') }}</div>
                    </div>
                </div>
                <div class="mt60">
                    <div class=" flex-box">
                        <div class="flex-grow-1">
                            <div class="fs30 lh34 col5a">{{ $t('footer.phone') }}</div>
                            <div class="mt10 fs30 lh34 cold2">{{ phone }}</div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="fs30 lh34 col5a">{{ $t('footer.address') }}</div>
                            <div class="mt10 fs30 lh34 cold2">{{ address }}</div>
                        </div>
                    </div>
                    <div class="mt30 flex-box">
                        <div class="flex-grow-1">
                            <div class="fs30 lh34 col5a">{{ $t('footer.email') }}</div>
                            <div class="mt10 fs30 lh34 cold2">{{ email }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_link2">
            <div class="w1760 h80 flex-box">
                <div class="fs25" v-html="copyright"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue';
const t = getCurrentInstance().appContext.config.globalProperties;
let isMobile = inject('isMobile')
let classifyList = ref([t.$t('headerTabs.productList.title1'), t.$t('headerTabs.productList.title2'), t.$t('headerTabs.productList.title3'), t.$t('headerTabs.productList.title4'), t.$t('headerTabs.productList.title5'), t.$t('headerTabs.productList.title6')]);
let exampleList = ref([t.$t('resultList.title1'), t.$t('resultList.title2'), t.$t('resultList.title3'), t.$t('resultList.title4'), t.$t('resultList.title5'), t.$t('resultList.title6'), t.$t('resultList.title7'), t.$t('resultList.title8'), t.$t('resultList.title9'), t.$t('resultList.title10')])
let language = ref(localStorage.getItem('language') || 'zh');
let phone = ref('')
let address = ref('')
let email = ref('')
let copyright = ref('')
let productList = ref([])
let resultList = ref([])
let successList = ref([])
let file1 = ref('')
import bus from '../utils/eventBus';
const { proxy } = getCurrentInstance();
const showSearch = () => {
    bus.emit('brotherEvent', true)
}
let goTop = (() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
})
onMounted(() => {
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        console.log(res);
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                phone.value = res.phone
                address.value = res.address
                email.value = res.serviceEmail
                copyright.value = res.copyright
            } else {
                phone.value = res.phoneEn
                address.value = res.addressEn
                email.value = res.serviceEmailEn
                copyright.value = res.copyrightEn
            }

        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    GetApiProductCategory()
    getApiSolutionCategory()
    getapiExampleCategory()
})
// 接口调用
// 产品列表
const GetApiProductCategory = (e) => {
    proxy?.$api.apiProductCategory({}).then((res) => {
        console.log('res', res);
        if (res.code == 200) {
            productList.value = res.data

        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
// 解决方案
const getApiSolutionCategory = () => {
    proxy?.$api.apiResultCategory({}).then(res => {
        if (res.code == 200) {
            resultList.value = res.data
            console.log('解决方案', res);

        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
// 成功案例
const getapiExampleCategory = () => {
    proxy?.$api.apiExampleCategory({}).then(res => {
        if (res.code == 200) {
            successList.value = res.data

        } else {
            proxy?.$message.error(res.msg);
        }

    })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.footer_nav {
    background: #1a1a1a;
}

.pt90 {
    padding-top: 90px;
}

.w126 {
    width: 205px;
}

.col23 {
    color: #fff;
}

.fs16.lh26 {
    line-height: 16PX;
}
.fs14.lh26 {
    line-height: 14PX;
}
.fs16.lh20 {
    line-height: 16PX;
}
.fs20{font-size: 20PX;}
.fs20.lh26 {
    line-height: 26px;
}
.w1340 {
    width: 1170px;
}

.footer_link1 {
    height: 620px;
}

.flex-align-end {
    align-items: flex-end;
}

.ico16 {
    width: 16px;
    height: auto;
}

.pr80 {
    padding-right: 80px;
}

.lh26 {
    line-height: 26px;
}

.mb15 {
    display: block;
    margin-bottom: 12px;
}

.aa {
    color: #d2d2d2;
}

.aa:hover {
    color: #1e50e6;
}

.right {
    position: relative;
    padding-left: 65px;

    &::before {
        content: '';
        width: 0.5px;
        height: 620px;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -90px;
        left: 0;
    }

    .mini_logo {
        width: 100px;
        height: auto;
    }
}

.arrow {
    width: 23px;
    height: auto;
}

.mt60 {
    margin-top: 60px;
}

.cold2 {
    color: #d2d2d2;
}

.ico40 {
    width: 40px;
    height: auto;
}

.code {
    width: 120px;
    height: auto;
    margin-top: 35px;
}

.footer_link2 {
    width: 100%;
    height: 80px;
    background: #000000;

    .h80 {
        height: 80px;
        font-size: 13px;
        color: #d2d2d2;
    }
}

.mobile {
    .footer_nav {
        background: #1a1a1a;
    }

    .w1760 {
        width: 100%;
    }

    .pt90 {
        padding: 0 30px 30px;
    }

    .w126 {
        width: 33%;
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
    }

    .col23 {
        color: #fff;
    }

    .fs16.lh26 {
        line-height: 26px;
    }

    .w1340 {
        width: 100%;
    }

    .footer_link1 {
        height: auto;
    }

    .flex-align-end {
        align-items: flex-end;
    }

    .ico16 {
        width: 16px;
        height: auto;
    }

    .pr80 {
        padding-right: 80px;
    }

    .lh26 {
        line-height: 26px;
    }

    .mb15 {
        display: block;
        margin-top: 10px;
    }

    .aa {
        color: #d2d2d2;
    }

    .aa:hover {
        color: #1e50e6;
    }

    .right {
        position: relative;
        padding-left: 0;
        margin-top: 30px;

        &::before {
            content: '';
            width: 0.5px;
            height: 620px;
            background: rgba(255, 255, 255, 0.1);
            position: absolute;
            top: -90px;
            left: 0;
            display: none;
        }

        .mini_logo {
            width: 200px;
            height: auto;
        }
    }

    .arrow {
        width: 30px;
        height: auto;
    }

    .mt60 {
        margin-top: 60px;
    }

    .cold2 {
        color: #d2d2d2;
    }

    .ico40 {
        width: 40px;
        height: auto;
    }

    .code {
        width: 120px;
        height: auto;
        margin-top: 35px;
    }

    .footer_link2 {
        width: 100%;
        height: auto;
        background: #000000;

        .h80 {
            height: auto;
            font-size: 30px;
            padding: 30px;
            color: #d2d2d2;
        }
    }
}
.fs13{font-size: 13PX;}
.fs14{font-size: 14PX;}
.fs16{font-size: 16PX;}
</style>
