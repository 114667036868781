<template>
  <nav>
  </nav>
  <router-view />
  <Fixed />
</template>
<script setup>
import { ref, provide, nextTick } from 'vue'
const isRouterActive = ref(true)
provide('reload', () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
})

</script>
<style lang="less">
.phone_view {
  width: 235px;
  height: 60px;
  padding-left: 15px;
  border-radius: 20px 0 0 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #898989;
  background: rgba(255, 255, 255, 0.8);
  right: -600px;
  opacity: 0;

}

.phone_view.big {
  height: 200px;
}

// .phone_view::before {
//   content: "";
//   width: 0px;
//   height: 0px;
//   border-top: 10px solid transparent;
//   border-bottom: 10px solid transparent;
//   border-left: 10px solid #fff;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 238px;
// }

.phone_show:hover .phone_view {
  opacity: 1;
  right: 62px;
}

.fiexed_nav .code {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>
