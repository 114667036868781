<!--
  时间：2024年07月02日 10:33:40
-->
<template>
  <div class="fiexed_nav">
    <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat"
      class="item fs12 flex-col flex-box flex-center" :style="{ backgroundImage: `url(${bg1})` }">
    
      <img src="../assets/icon/ico_support.png" class="ico" alt="">
      <div>{{ $t('fix.title1') }}</div>
    </a>
    <div class="item fs12 pr flex-col flex-box cp flex-center phone_show" :style="{ backgroundImage: `url(${bg2})` }">
      <img src="../assets/icon/ico_phone.png" class="ico" alt="">
      <div>{{ $t('fix.title2') }}</div>
      <div class="phone_view flex-box cu">
        <div class="fs12 fwb col0">{{ $t('fix.title4') }}</div>
        <div class="fs16 fwb col2 pl5">{{ serviceHotline }}</div>
      </div>
    </div>
    <!-- <div class="item pr flex-col flex-box flex-center phone_show">
          <img src="../assets/icon/ico_weixin.png" class="ico" alt="">
          <div>官方微信</div>
          <div class="phone_view big flex-box flex-center">
            <img :src="publicAccountQrcode" class="code" alt="">
          </div>
        </div> -->
    <router-link to="/server" class="item fs12 flex-col flex-box flex-center" :style="{ backgroundImage: `url(${bg3})` }">
      <img src="../assets/icon/ico_kefu.png" class="ico" alt="">
      <div>{{ $t('fix.title3') }}</div>
    </router-link>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
export default {
  // 局部注册的组件
  components: {},
  setup() {
    let language = ref(localStorage.getItem('language') || 'zh');
    let serviceHotline = ref('')
    let publicAccountQrcode = ref('')
    onMounted(() => {
      const { proxy } = getCurrentInstance();

      // 使用全局方法
      proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
          if (language.value=='zh') {
            serviceHotline.value = res.phone
          }else{
            serviceHotline.value = res.phoneEn
          }
        } else {
          proxy?.$message.error(data_res.msg);
        }
      });
    });

    return {

      serviceHotline: serviceHotline,
      publicAccountQrcode: publicAccountQrcode
    };
  },
  // 组件状态值
  data() {
    return {
      bg1: require('../assets/icon/fix1.png'),
      bg2: require('../assets/icon/fix2.png'),
      bg3: require('../assets/icon/fix3.png')
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.fiexed_nav {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 20;
  width: 64px;

  .item {
    width: 60px;
    height: 60px;
    margin-top: 2px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-flex;
    color: #fff;
    font-weight: bold;

    .ico {
      width: 28.5px;
      height: auto;
      margin-bottom: 2px;
    }
  }
}
.fs12{font-size: 12px;}
.fs16{font-size: 18px;}
</style>
