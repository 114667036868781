
// 定义中文语言包对象
export default {
  headerTabs: {
    tab1: '产品中心',
    tab2: '解决方案',
    tab3: '成功案例',
    tab4: '服务与支持',
    tab5: '学习园地',
    tab6: '关于我们',
    tab7: '正泰电器',
    tab8: '登录/注册',
    popuptitle1: '工业自动化',
    productList: {
      title1: '变频器',
      title2: '软启动器',
      title3: '伺服系统',
      title4: '可编程逻辑控制器',
      title5: '人机交互',
      title6: '其他',
    },
  },
  product: {
    title1: '概述',
    title9: '产品概述',
    title2: '产品优势',
    title3: '产品特点',
    title4: '规格参数',
    title5: '资料下载',
    title6: '成功案例',
    title7: '相关产品',
    title8: '获得支持',
    btn1: '产品资料',
    btn2: '查看详情',
    searchTitle: '按产品类别查找',
    subtitle: '产品代表我的心，我一直在这里等你',
    sub:'主要特点'
  },
  download: {
    btn1: '文档下载',
    btn2: '在线查看',
    classify1: '产品手册',
    classify2: '彩页/样本',
    classify3: '证书下载',
    classify4: '图纸',
    classify5: '附件',
    classify6: '应用软件',
  },
  newsList: {
    title: '新闻中心',
    subtitle1: '新闻信息',
    subtitle2: '上一条新闻',
    subtitle3: '下一条新闻',
    time:'发布时间',
    tips:'大家都在看'
  },
  resultList: {
    title1: '起重机械',
    title2: '物流仓储',
    title3: '塑料橡胶',
    title4: '电子制造',
    title5: '压缩机',
    title6: '印刷包装',
    title7: '木工机械',
    title8: '光伏设备',
    title9: '机床',
    title10: '钢铁冶金',
    title11: '石油化工',
    title12: '电力',
    title13: '建材',
    title14: '采矿',
    title15: '水行业',
    title16: '港口',
    title17: '造纸',
    title18: '市政工程',
    title19: '其它',
    title20: '方案介绍',
    title21: '方案优势',
    title22: '系统方案拓扑图',
    title23: '方案客户价值'
  },
  exampleList: {
    title1: '案例介绍',
    title2: '工艺要求',
    title3: '方案配置',
    title4: '现场图片/视频',
    title5: '客户价值'
  },
  support: {
    title1: '客服中心',
    title2: '下载中心',
    subtitle1: '服务热线',
    subtitle2: '在线客服',
    subtitle3: '服务邮箱',
    subtitle4: '售后服务',
    subtitle5: '维修业务',
    subtitle6: '售前咨询',
    subtitle7: '意见反馈',
    subtitle8: '产品查询',
    subtitle9: '自助服务',
    content3: '技术服务咨询欢迎发送邮件'
  },
  contact: {
    tips: '全国统一服务热线',
    title2: '公司位置',
    title3: '分支机构'
  },
  about: {
    title1: '关于精泰',
    title2: '公司荣誉',
    title3: '公司动态',
    title4: '加入我们',
    title5: '正泰集团',
    title6: '招商加盟',
    title7: '联系我们',
    title8: '集团简介',
    title9: '正泰荣誉',
    title10: '精泰简介',
    title11: '发展历程',
    title15: '公司发展历程',
    title12: '正泰简介',
    title13: '公司介绍',
    title14: '成员企业',
    title16:'产品系统图'
  },
  footer: {
    tab1: '产品中心',
    tab2: '解决方案',
    tab3: '成功案例',
    tab4: '服务与支持',
    tab5: '关于我们',
    tab6: '学习园地',
    top: '顶部',
    phone: '电话',
    address: '地址',
    email: '邮箱',
    servernumber: '官方服务号',
    focus: '关注我们',
    copyright: '© 上海精泰技术有限公司 All Rights Reserved 浙ICP备11016013号'
  },
  needHelp: {
    sub: '为你提供产品资料下载、在线视频学习、售后服务、售前咨询、服务网络查询等服务',
    title1: '售前咨询',
    title2: '资料下载',
    title3: '售后服务',
    title4: '服务网络',
    content1: '为你提供产品选型、产品咨询、应用解疑等。',
    content2: '为你提供产品手册、说明书、图纸、应用软件等下载。',
    content3: '售后服务介绍、在线报修、防伪查询、增值服务等。',
    content4: '提供全国技术服务网点查询。',
    btn_text1: '立即咨询',
    btn_text2: '立即下载',
    btn_text3: '立即申请',
  },
  indexHtml: {
    btn:'查看详情',
    banner1: {
      title: '正泰精泰，引领自动化潮流',
      subtitle: '副标题副标题副标题副标题副标题副标题副标题副标题副标题副标题',
      btn_text: '发现更多'
    },
    subtitle1: '产品代表我的心，我一直在这里等你',
    subtitle2: '深入理解设备工艺，满足新的需求，探索更高价值的技术方案，为客户实现更高价值',
    subtitle3: '以行业成功案例为基础，满足客户个性化需求，实现更短的开发周期和更省的开发成本',
    tab_text1: '公司新闻',
    tab_text2: '媒体报道',
    tab_text3: '展会活动',
    tab_text4: '精泰视频',
    subtitle5: '为你提供产品技术、应用、维护相关的\n学习视频和文档，快速导入所需的知识点',
    subtitle6: '正有所为，泰有未来\n建立多样化的人才引进渠道，网罗全球范围内的杰出人才'
  },
  productHtml: {
    banner1: {
      title: '产品中心',
      subtitle: '产品代表我的心，我一直在这里等你',

    },
  },
  search: {
    title1: '关键字快速搜索',
    title2: '分类搜索',
  },
  form:{
    feedback:'意见反馈',
    name:'您的姓名',
    nametips:'请输入您的姓名',
    phone:'手机号',
    phonetips:'请输入您的11位手机号',
    submit:'提交',
    type:'意见类型',
    typetips:'请选择意见类型',
    problem:'问题描述',
    problemtips:'请输入问题描述'
  },
  fix:{
    title1:'在线咨询',
    title2:'电话咨询',
    title3:'获取支持',
    title4:'服务热线'
  }

}