
// 定义英文语言包对象
export default {
  headerTabs: {
    tab1: 'Products',
    tab2: 'Solutions',
    tab3: 'Case',
    tab4: 'Support',
    tab5: 'Learning',
    tab6: 'About US',
    tab7: 'Chint',
    tab8: 'Login/Register',
    popuptitle1: 'Factory Automation',
    productList: {
      title1: 'Variable Frequency Drives',
      title2: 'Soft Starter',
      title3: 'Servo System',
      title4: 'PLC',
      title5: 'HMI',
      title6: 'Other',
    }
  },
  product: {
    title1: 'Overview',
    title9: 'Product Overview',
    title2: 'Advantage',
    title3: 'Features',
    title4: 'Specification',
    title5: 'Download',
    title6: 'Application',
    title7: 'Related Products',
    title8: 'Get Support',
    btn1: 'Product Information',
    btn2: 'Viewing',
    searchTitle: 'Search by product category',
    subtitle: 'The product represents my heart and I have been waiting for you here',
    sub: 'Features'
  },
  download: {
    btn1: 'Download',
    btn2: 'Viewing',
    classify1: 'Product manual',
    classify2: 'Color page/sample',
    classify3: 'Certificate Download',
    classify4: 'Drawing',
    classify5: 'Annex',
    classify6: 'Application software',
  },
  newsList: {
    title: 'News',
    subtitle1: 'News Information',
    subtitle2: 'Previous News',
    subtitle3: 'Next News',
    time: 'Date',
    tips: 'Everyone is watching'
  },
  resultList: {
    title1: 'Crane & Hoist',
    title2: 'Textile Machinery ',
    title3: 'Logistics',
    title4: 'Rubber & Plastic',
    title5: 'Compressor',
    title6: 'Printing & Packaging',
    title7: 'Woodworking',
    title8: 'Solar Pump',
    title9: 'Machine Tool',
    title10: 'Metal & Metallurgy',
    title11: 'Petrochemical',
    title12: 'Power',
    title13: 'Building Materials',
    title14: 'Mining',
    title15: 'Water & Wastewater',
    title16: 'Port',
    title17: 'Paper Making',
    title18: 'Municipal Engineering',
    title19: 'Other',
    title20: 'Introduction',
    title21: 'Advantage',
    title22: 'Solution',
    title23: 'Solution Value'
  },
  exampleList: {
    title1: 'Introduction',
    title2: 'Customer Needs',
    title3: 'Solutions',
    title4: 'On site pictures&videos',
    title5: 'Solution Value'
  },
  support: {
    title1: 'Customer Service Center',
    title2: 'Download',
    subtitle1: 'Service Hotline',
    subtitle2: 'Online Service',
    subtitle3: 'E-mail',
    subtitle4: 'After Sales',
    subtitle5: 'Repair',
    subtitle6: 'Technical Support',
    subtitle7: 'Feedback',
    subtitle8: 'Product Query',
    subtitle9: 'Self Service',
    content3: 'Welcome to send an email for technical service consultation'
  },
  contact: {
    tips: 'National service hotline',
    title2: 'Company location',
    title3: 'Branches'
  },
  about: {
    title1: 'Company Profile',
    title2: 'Qualification Honor',
    title3: 'Company News',
    title4: 'Join Us',
    title5: 'GROUP OVERVIEW',
    title6: 'Franchise',
    title7: 'Contact',
    title8: 'GROUP OVERVIEW',
    title9: 'Qualification Honor',
    title10: 'Company Profile',
    title11: 'development history',
    title15: 'development history',
    title12: 'GROUP OVERVIEW',
    title13: 'Company Introduction',
    title14: 'Industry Portals',
     title16:'Product System Diagram'
  },
  needHelp: {
    sub: 'We provide you with services such as product information download, online video learning, after-sales service, pre-sales consultation, and service network inquiry',
    title1: 'Technical Support',
    title2: 'Download',
    title3: 'After Sales',
    title4: 'Service Network',
    content1: 'Product selection and consultation, technical supports.',
    content2: 'Product manuals, instructions, drawings etc. downloads.',
    content3: 'Service introduction, online repair, inquiry, etc.',
    content4: 'Provide nationwide technical service network inquiries.',
    btn_text1: 'consultation',
    btn_text2: 'Download',
    btn_text3: 'application',
  },
  indexHtml: {
    btn: 'Viewing',
    banner1: {
      title: 'CHINT PRECISION，leads the trend of automation',
      subtitle: '副标题副标题副标题副标题副标题副标题副标题副标题副标题副标题',
      btn_text: 'More'
    },
    subtitle1: '产品代表我的心，我一直在这里等你',
    subtitle2: 'In-depth understanding of equipment technology, to meet new needs, to explore higher value technical solutions, to achieve higher value for customers',
    subtitle3: 'Based on successful industry cases, meet personalized customer needs, achieve shorter development cycles and lower development costs',
    tab_text1: 'Company News',
    tab_text2: '媒体报道',
    tab_text3: '展会活动',
    tab_text4: '精泰视频',
    subtitle5: 'We provide you with learning videos and documents related to product technology, applications, \nand maintenance, allowing you to quickly import the required knowledge points',
    subtitle6:'Taking action, Tai has a future\nEstablish diverse channels for talent introduction and attract outstanding talents from around the world'
  },
  footer: {

    top: 'Top',
    phone: 'Telephone',
    address: 'Address',
    email: 'Email',
  },
  search: {
    title1: 'Search Engines',
    title2: 'Category search',
  },
  form: {
    feedback: 'Feedback',
    name: 'Name',
    nametips: 'Please enter your name',
    phone: 'Cell-phone number',
    phonetips: 'Please enter your mobile phone number',
    submit: 'Submit',
    type: 'Opinion type',
    typetips: 'Please select the type of opinion',
    problem: 'Problem description',
    problemtips: 'Please enter a problem description'
  },
  fix:{
    title1:'Online',
    title2:'Telephone',
    title3:'Support',
    title4:'Telephone'
  }
}