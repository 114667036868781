<!--
 * @author: JJ
 * @since: 2024-07-18
 * ResultView.vue
-->
<template>
    <div class="container bg-f6" :class="isMobile ? 'mobile' : ''">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col"
                        :style="{ backgroundImage: `url(${resultInfo?.baseInfo?.banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60 fwb">{{
                                language == 'zh' ? resultInfo?.baseInfo?.categoryName :
                                    resultInfo?.baseInfo?.categoryNameEn
                            }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white " :class="isMobile ? 'p30' : 'pt70 pb100'">
            <div class="w1760">
                <div class="title">{{ $t('product.title1') }}</div>
                <div class="flex mt50" v-if="!isMobile">
                    <div class="flex-grow-1 pr110 fs15 lh25 col5a">
                        <span
                            v-html="language == 'zh' ? resultInfo?.baseInfo?.articleAbstract : resultInfo?.baseInfo?.articleAbstractEn"></span>
                    </div>
                    <img :src="resultInfo?.baseInfo?.cover" class="cover" alt="">
                </div>
                <div class="mt30" v-else>
                    <img :src="resultInfo?.baseInfo?.cover" class="cover" alt="">
                    <div class="fs30 mt30 col5a">
                        <span
                            v-html="language == 'zh' ? resultInfo?.baseInfo?.articleAbstract : resultInfo?.baseInfo?.articleAbstractEn"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-246 pt65 pb50" v-if="solutionVoList?.length > 0">
            <div class="w1760">
                <div class="flex flex-align-end pr" v-if="!isMobile">
                    <div class="title">{{ $t('headerTabs.tab2') }}</div>
                    <div class="tab_nav flex-grow-1" v-if="resultChildList?.length > 0">
                        <div class="tab_item" @click="chooseNewsTab(index, item.categoryId)"
                            :class="[nindex == index ? 'active' : '']" :key="index"
                            v-for="(item, index) in resultChildList">{{
                                language == 'zh' ? item?.categoryName : item?.categoryNameEn }}</div>
                    </div>
                </div>
                <div class="pr plr30" v-else>
                    <div class="title">{{ $t('headerTabs.tab2') }}</div>
                    <div class="tab_nav" v-if="resultChildList?.length > 0">
                        <div class="tab_item" @click="chooseNewsTab(index, item.categoryId)"
                            :class="[nindex == index ? 'active' : '']" :key="index"
                            v-for="(item, index) in resultChildList">{{
                                language == 'zh' ? item?.categoryName : item?.categoryNameEn }}</div>
                    </div>
                </div>
            </div>
            <!-- <template v-for="(item, index) in resultList" :key="index"> -->
            <template v-if="resultList?.solutionVoList?.length > 1">
                <swiper :slides-per-view="isMobile ? 1 : 1.9" :centeredSlides="true" :centeredSlidesBounds="true"
                    @swiper="onSwiper1" @slideChange="slideChange" class="swiper_result" :observer="true"
                    :observeParents="true" :modules="modules" loop>
                    <swiper-slide class="swiper_result_item" v-for="(item, index) in resultList.solutionVoList"
                        :key="index">
                        <router-link :to="'/ResultInfoView?id=' + item.articleId" class="result_item"
                            :class="[midActive == index ? 'colf' : 'col0']"
                            :style="{ backgroundImage: `url(${item?.cover})` }">
                            <img src="../assets/icon/moudle.png" v-if="midActive == index" class=" moudle" alt="">
                            <div class="pr z2">
                                {{ language == 'zh' ? item?.title : item?.titleEn }}
                            </div>
                        </router-link>
                    </swiper-slide>
                </swiper>
                <div class="diy_pagination_nav" v-if="!isMobile">
                    <div class="sq"></div>
                    <div class="flex-box flex-center">
                        <div class="sq_item" v-for="(item, index) in resultList.solutionVoList" :key="index"
                            @click="changeMid(index)" :class="[midActive == index ? 'active' : '']">
                            {{ language == 'zh' ? item?.title : item?.titleEn }}</div>
                    </div>
                </div>
            </template>
            <template v-if="resultList?.solutionVoList?.length == 1">
                <div class="swiper_result">
                    <div class="swiper_result_item mlra" v-for="(item, index) in resultList.solutionVoList"
                        :key="index">
                        <router-link :to="'/ResultInfoView?id=' + item.articleId" class="result_item"
                            :class="[midActive == index ? 'colf' : 'col0']"
                            :style="{ backgroundImage: `url(${item?.cover})` }">
                            <img src="../assets/icon/moudle.png" v-if="midActive == index" class=" moudle" alt="">
                            <div class="pr z2">
                                {{ language == 'zh' ? item?.title : item?.titleEn }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="diy_pagination_nav" v-if="!isMobile">
                    <div class="sq"></div>
                    <div class="flex-box flex-center">
                        <div class="sq_item" v-for="(item, index) in resultList.solutionVoList" :key="index"
                            @click="changeMid(index)" :class="[midActive == index ? 'active' : '']">
                            {{ language == 'zh' ? item?.title : item?.titleEn }}</div>
                    </div>
                </div>
            </template>
            <template v-if="resultList?.solutionVoList?.length<1 || resultList?.solutionVoList==[]">
              <img src="../assets/icon/null_data.png" class="null" alt="">
            </template>
            <!-- </template> -->

        </div>
        <div class="w1760 pt70 pb120" v-if="resultInfo?.caseList?.length > 0">
            <div class="title">{{ $t('headerTabs.tab3') }}</div>
            <div class=" pt90 scroll">
                <router-link :to="'/ExampleInfoView?id=' + item.articleId" class="example_link"
                    v-for="(item, index) in resultInfo?.caseList" :key="index">
                    <div class="example_cover" :style="{ backgroundImage: `url(${item?.cover})` }"></div>
                    <div class="m-ellipsis-l2 m1"><span>{{ language == 'zh' ? item?.title : item?.titleEn }}</span>
                    </div>
                </router-link>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, watch, inject } from "vue"
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const { proxy } = getCurrentInstance();
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let categoryId = ref()
const modules = [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax];
const t = getCurrentInstance().appContext.config.globalProperties;
let swiperBanner = ref(['XXX解决方案', 'XXX解决方案', '压缩机解决方案', 'XXX解决方案', 'XXX解决方案'])
let banner1 = ref(require('../assets/icon/banner1.png'))
let swi1 = ref('')
let midActive = ref('')
let tab = ref(['鼓风机', '空压机', '制冷压缩机']);
let nindex = ref(-1)
let isMobile = inject('isMobile')
onMounted(() => {
    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});
const onSwiper1 = (e) => {
    swi1.value = e
}
const slideChange = (e) => {
    midActive.value = e.realIndex
}
const changeMid = (e) => {
    swi1.value.slideToLoop(e)
    midActive.value = e
}
const chooseNewsTab = (index, id) => {
    nindex.value = index
    console.log(resultChildList.value[index]);
    resultList.value = resultChildList.value[index]
}
let resultInfo = ref([])
var getApiResultCategorytInfo = (id) => {
    proxy?.$api.apiResulCategorytInfo({ id }).then(res => {
        console.log('resultinfo', res);
        if (res.code == 200) {
            resultInfo.value = res.data
        } else {
            proxy.$message.error(res.msg);
        }

    })
}
let resultList = ref([])
// 解决方案列表接口
// var getApiResultList = () => {
//     proxy?.$api.apiResultList({}).then(res => {
//         console.log('result', res);
//         if (res.code == 200) {
//             resultList.value = res.data
//         } else {
//             proxy.$message.error(res.msg);
//         }

//     })
// }
let resultChildList = ref([])
let solutionVoList = ref([])
var getApiResultChildList = (id) => {
    proxy?.$api.apiResultListChild({ 'categoryId': id }).then(res => {
        console.log('child', res.data[0]);
        if (res.code == 200) {
            resultChildList.value = res.data[0].childrenCategory
            resultList.value = res.data[0];
            solutionVoList.value = res.data[0].solutionVoList
            console.log(typeof(solutionVoList.value));
            
        } else {
            proxy.$message.error(res.msg);
        }

    })
}

watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.id) {
            categoryId.value = router.currentRoute.value.query?.id
            getApiResultCategorytInfo(categoryId.value)
            // getApiResultList()
            getApiResultChildList(categoryId.value)
            // getApiProductList(1, 12, categoryId.value, null)
            // console.log(categoryId.value);
        }

    }, { immediate: true, deep: true })
</script>
<style lang="less" scoped>
.lh25 {
    line-height: 35px;
}

.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 525);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 525);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.pb100 {
    padding-bottom: 100px;
}

.pr110 {
    padding-right: 110px;
}

.cover {
    width: 800px;
    height: 450px;
    border-radius: 10px;
    object-fit: cover;
}

.swiper_result {
    width: 100%;
    height: 450px;
    margin-top: 60px;

    &_item {
        width: 900px;
        height: 450px;
    }

    .result_item {
        width: 900px;
        height: 450px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        padding: 50px;
        font-size: 36px;
        line-height: 110px;
        font-weight: bold;
        display: block;
        border-radius: 10px;
        position: relative;
    }
}

.diy_pagination_nav {
    width: 1760px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;

    .sq {
        background: rgb(210, 210, 210);
        width: 1760px;
        height: 2px;
    }

    .sq_item {
        position: relative;
        font-size: 24px;
        color: #5a5a5a;
        text-align: center;
        padding-top: 20px;

        &.active {
            color: rgb(30, 80, 230);
        }

        &.active::before {
            content: '';
            width: 180px;
            height: 5px;
            position: absolute;
            top: -2.5px;
            left: 50%;
            transform: translateX(-50%);
            background: rgb(30, 80, 230);
        }
    }
}

.sq_item+.sq_item {
    margin-left: 120px;
}

.tab_nav {
    .tab_item {
        min-width: 118px;
        height: 20px;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        color: #5a5a5a;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        padding-left: 30px;
        padding-right: 30px;
    }

    .tab_item.active {
        color: rgb(30, 80, 230)
    }

    .tab_item+.tab_item {
        border-left: 1px solid #5a5a5a;
    }
}

.example_link {
    width: 420px;
    display: inline-block;
    vertical-align: top;
    font-size: 24px;
    line-height: 40px;
    color: #231414;

    .m1 {
        width: 420px;
        display: -webkit-box;
        height: 80px;
    }

    .example_cover {
        display: block;
        width: 420px;
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        margin-bottom: 40px;
    }

    .example_cover:hover {
        background-size: 110% 110%;
    }

    &+& {
        margin-left: 25px;
    }
}

.example_link:hover {
    color: #1e50e6;
}

.pt70 {
    padding-top: 70px;
}

.pb120 {
    padding-bottom: 120px;
}

.pt90 {
    padding-top: 90px;
}

.scroll {
    overflow-x: auto;
    white-space: nowrap;
}
.null{
    width: 300px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}
.mobile {
    .lh25 {
        line-height: 35px;
    }

    .w1760 {
        width: 100%;
    }

    .swiper_link {
        margin-top: 100px;
        width: 100%;
        height: calc(100vw / 1920 * 550);
        position: relative;

        .swiper_sw {
            width: 100%;
            height: calc(100vw / 1920 * 550);
        }

        .swiper_item {
            width: 100%;
            height: 100%;
        }

        .top_banner {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }

        .top_banner_info {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        .top_btn {
            margin-top: 140px;
            width: 140px;
            height: 54px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 10px;
        }
    }

    .pb100 {
        padding-bottom: 100px;
    }

    .pr110 {
        padding-right: 110px;
    }

    .cover {
        width: 800px;
        height: 450px;
        border-radius: 10px;
        object-fit: cover;
    }

    .swiper_result {
        width: 690px;
        height: 450px;
        margin-top: 60px;
margin-left: auto;
margin-right: auto;
        &_item {
            width: 100%;
            height: 450px;
        }

        .result_item {
            width: 100%;
            height: 450px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            padding: 30px;
            font-size: 36px;
            line-height: 110px;
            font-weight: bold;
            display: block;
            border-radius: 10px;
            position: relative;
        }
    }

    .diy_pagination_nav {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        .sq {
            background: rgb(210, 210, 210);
            width: 1760px;
            height: 2px;
        }

        .sq_item {
            position: relative;
            font-size: 24px;
            color: #5a5a5a;
            text-align: center;
            padding-top: 20px;

            &.active {
                color: rgb(30, 80, 230);
            }

            &.active::before {
                content: '';
                width: 180px;
                height: 5px;
                position: absolute;
                top: -2.5px;
                left: 50%;
                transform: translateX(-50%);
                background: rgb(30, 80, 230);
            }
        }
    }

    .sq_item+.sq_item {
        margin-left: 120px;
    }

    .tab_nav {
        margin-top: 30px;
        white-space: nowrap;
        overflow-x: auto;

        .tab_item {
            min-width: 118px;
            height: 40px;
            text-align: center;
            font-size: 30px;
            line-height: 40px;
            color: #5a5a5a;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            padding-left: 30px;
            padding-right: 30px;
        }

        .tab_item.active {
            color: rgb(30, 80, 230)
        }

        .tab_item+.tab_item {
            border-left: 1px solid #5a5a5a;
        }
    }

    .example_link {
        width: 420px;
        display: inline-block;
        vertical-align: top;
        font-size: 24px;
        line-height: 40px;
        color: #231414;

        .m1 {
            width: 420px;
            display: -webkit-box;
            height: 80px;
        }

        .example_cover {
            display: block;
            width: 420px;
            height: 350px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
            position: relative;
            z-index: 1;
            border-radius: 10px;
            margin-bottom: 40px;
        }

        .example_cover:hover {
            background-size: 110% 110%;
        }

        &+& {
            margin-left: 25px;
        }
    }

    .example_link:hover {
        color: #1e50e6;
    }

    .pt70 {
        padding-top: 70px;
    }

    .pb120 {
        padding-bottom: 120px;
    }

    .pt90 {
        padding-top: 90px;
    }

    .scroll {
        overflow-x: auto;
        white-space: nowrap;
    }
}
/deep/.title::before {
    display: none !important;
}
</style>
